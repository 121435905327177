import { Auth } from 'aws-amplify';

import config from './config';

export default {
    Auth: {
        mandatorySignIn: true,
        region: config.REGION,
        userPoolId: config.COGNITO_USER_POOL_ID,
        userPoolWebClientId: config.COGNITO_APP_CLIENT_ID,
        oauth: {
            domain: config.COGNITO_DOMAIN,
            scope: ['email', 'openid'],
            redirectSignIn: config.COGNITO_REDIRECT_SIGN_IN ?? `${window.location.origin}/`,
            redirectSignOut: config.COGNITO_REDIRECT_SIGN_OUT ?? `${window.location.origin}/`,
            responseType: 'code',
        },
    },
    API: {
        endpoints: [
            {
                name: 'admin',
                endpoint: config.API_URL,
                region: config.REGION,
                custom_header: async () => ({
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                    'x-api-key': config.API_KEY,
                    version: 1,
                }),
            },
        ],
    },
};

import * as Sentry from '@sentry/react';
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';

interface ISentry {
    sentryDSN: string;
    env?: 'stage' | 'prod' | string;
    release?: string;
    tracesSampleRate?: number;
    denyUrls?: RegExp[];
    ignoreErrors?: string[];
    normalizeDepth?: number;
}

export default class SentryClient {
    sentryDSN: string;
    env: 'stage' | 'prod' | string;
    release?: string;
    tracesSampleRate?: number;
    normalizeDepth?: number;
    private isInited: boolean;
    private denyUrls?: RegExp[];
    private ignoreErrors?: string[];
    sentry: typeof Sentry;

    constructor({ sentryDSN, denyUrls, ignoreErrors, normalizeDepth, env, release, tracesSampleRate }: ISentry) {
        this.sentryDSN = sentryDSN;
        this.env = env || 'stage';
        this.release = release || '1.0.0';
        this.tracesSampleRate = tracesSampleRate || 1.0;
        this.isInited = false;
        this.denyUrls = denyUrls;
        this.ignoreErrors = ignoreErrors;
        this.normalizeDepth = normalizeDepth;
        this.sentry = Sentry;
    }

    init() {
        if (this.isInited) return;

        Sentry.init({
            dsn: this.sentryDSN,
            sampleRate: 1,
            tracesSampleRate: this.tracesSampleRate,
            release: this.release,
            environment: this.env,
            integrations: [new ExtraErrorDataIntegration()],
            ignoreErrors: this.ignoreErrors || [],
            denyUrls: this.denyUrls || [],
            normalizeDepth: this.normalizeDepth || 3,
            // NOTE: Remove this line if you want to see the error stack trace in Sentry
            enabled: this.env !== 'development',
            autoSessionTracking: true,
        });

        this.isInited = true;
    }

    public setUserData(data: Record<string, any>) {
        this.sentry.setUser(data);
    }
}

import React, { Component } from 'react';

import { logger } from 'utils/logger';
import { ERROR_REASONS } from 'utils/logger/transports/constants';

import { Props, State } from './types';

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        error: null,
        hasError: false,
    };

    componentDidCatch(error: Error) {
        this.setState({ error });

        logger.error(Error('General error:'), {
            cause: error,
            tags: {
                reason: ERROR_REASONS.general,
                appLevelError: true,
            },
        });
    }

    public static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return <h1>Unexpected error</h1>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;

import React, { useMemo } from 'react';

import usePermission, { PermitProps } from 'hooks/permission/usePermission';

import NoPermissionFallback from './components/NoPermissionFallback';

interface Props extends PermitProps {
    children: React.ReactNode;
    withFallback?: boolean;
}
const PermissionWrapper = ({ children, withFallback = false, ...rest }: Props) => {
    const { getIsPermit } = usePermission();

    const FallbackScreen = () => {
        return withFallback ? <NoPermissionFallback /> : null;
    };

    const isContentPermit = useMemo(() => {
        if (
            getIsPermit({
                ...rest,
            })
        ) {
            return children;
        } else {
            return FallbackScreen();
        }
    }, [children, rest]);

    return <>{isContentPermit}</>;
};

export default PermissionWrapper;
